/**
 * This file was generated automatically by Supernova.io and should not be changed manually.
 * To modify the format or content of this file, please contact your design system team. 
 */

:root {
  --ds-size-width-w-0: 0rem;
  --ds-size-height-h-0: 0rem;
  --ds-size-height-h-px: 0.063rem;
  --ds-size-height-h-0-5: 0.125rem;
  --ds-size-height-h-1: 0.25rem;
  --ds-size-height-h-1-5: 0.375rem;
  --ds-size-height-h-2: 0.5rem;
  --ds-size-height-h-2-5: 0.625rem;
  --ds-size-height-h-3: 0.75rem;
  --ds-size-height-h-3-5: 0.875rem;
  --ds-size-height-h-4: 1rem;
  --ds-size-height-h-5: 1.25rem;
  --ds-size-height-h-6: 1.5rem;
  --ds-size-height-h-7: 1.75rem;
  --ds-size-height-h-8: 2rem;
  --ds-size-height-h-9: 2.25rem;
  --ds-size-height-h-10: 2.5rem;
  --ds-size-height-h-11: 2.75rem;
  --ds-size-height-h-12: 3rem;
  --ds-size-height-h-14: 3.5rem;
  --ds-size-height-h-16: 4rem;
  --ds-size-height-h-20: 5rem;
  --ds-size-height-h-24: 6rem;
  --ds-size-height-h-28: 7rem;
  --ds-size-height-h-32: 8rem;
  --ds-size-height-h-36: 9rem;
  --ds-size-height-h-44: 11rem;
  --ds-size-height-h-48: 12rem;
  --ds-size-height-h-52: 13rem;
  --ds-size-height-h-56: 14rem;
  --ds-size-height-h-64: 16rem;
  --ds-size-height-h-72: 18rem;
  --ds-size-height-h-80: 20rem;
  --ds-size-height-h-96: 24rem;
  --ds-size-width-w-px: 0.063rem;
  --ds-size-width-w-0-5: 0.125rem;
  --ds-size-width-w-1: 0.25rem;
  --ds-size-width-w-1-5: 0.375rem;
  --ds-size-width-w-2: 0.5rem;
  --ds-size-width-w-2-5: 0.625rem;
  --ds-size-width-w-3: 0.75rem;
  --ds-size-width-w-3-5: 0.875rem;
  --ds-size-width-w-4: 1rem;
  --ds-size-width-w-5: 1.25rem;
  --ds-size-width-w-6: 1.5rem;
  --ds-size-width-w-7: 1.75rem;
  --ds-size-width-w-8: 2rem;
  --ds-size-width-w-9: 2.25rem;
  --ds-size-width-w-10: 2.5rem;
  --ds-size-width-w-11: 2.75rem;
  --ds-size-width-w-12: 3rem;
  --ds-size-width-w-14: 3.5rem;
  --ds-size-width-w-16: 4rem;
  --ds-size-width-w-20: 5rem;
  --ds-size-width-w-24: 6rem;
  --ds-size-width-w-28: 7rem;
  --ds-size-width-w-32: 8rem;
  --ds-size-width-w-36: 9rem;
  --ds-size-width-w-44: 11rem;
  --ds-size-width-w-48: 12rem;
  --ds-size-width-w-52: 13rem;
  --ds-size-width-w-56: 14rem;
  --ds-size-width-w-64: 16rem;
  --ds-size-width-w-72: 18rem;
  --ds-size-width-w-80: 20rem;
  --ds-size-width-w-96: 24rem;
  --ds-size-breakpoint-sm: 40rem;
  --ds-size-breakpoint-md: 48rem;
  --ds-size-breakpoint-lg: 64rem;
  --ds-size-breakpoint-xl: 80rem;
  --ds-size-breakpoint-2xl: 96rem;
  --ds-size-max-width-max-w-0: 0rem;
  --ds-size-max-width-max-w-3xs: 16rem;
  --ds-size-max-width-max-w-2xs: 18rem;
  --ds-size-max-width-max-w-xs: 20rem;
  --ds-size-max-width-max-w-sm: 24rem;
  --ds-size-max-width-max-w-md: 28rem;
  --ds-size-max-width-max-w-lg: 32rem;
  --ds-size-max-width-max-w-xl: 36rem;
  --ds-size-max-width-max-w-2xl: 42rem;
  --ds-size-max-width-max-w-3xl: 48rem;
  --ds-size-max-width-max-w-4xl: 56rem;
  --ds-size-max-width-max-w-5xl: 64rem;
  --ds-size-max-width-max-w-6xl: 72rem;
  --ds-size-max-width-max-w-7xl: 80rem;
}