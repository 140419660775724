/**
 * This file was generated automatically by Supernova.io and should not be changed manually.
 * To modify the format or content of this file, please contact your design system team. 
 */

:root {
  --ds-space-spacing-0: 0rem;
  --ds-space-spacing-px: 0.063rem;
  --ds-space-spacing-0-5: 0.125rem;
  --ds-space-spacing-1: 0.25rem;
  --ds-space-spacing-1-5: 0.375rem;
  --ds-space-spacing-2: 0.5rem;
  --ds-space-spacing-2-5: 0.625rem;
  --ds-space-spacing-3: 0.75rem;
  --ds-space-spacing-3-5: 0.875rem;
  --ds-space-spacing-4: 1rem;
  --ds-space-spacing-5: 1.25rem;
  --ds-space-spacing-6: 1.5rem;
  --ds-space-spacing-7: 1.75rem;
  --ds-space-spacing-8: 2rem;
  --ds-space-spacing-9: 2.25rem;
  --ds-space-spacing-10: 2.5rem;
  --ds-space-spacing-11: 2.75rem;
  --ds-space-spacing-12: 3rem;
  --ds-space-spacing-14: 3.5rem;
  --ds-space-spacing-16: 4rem;
  --ds-space-spacing-20: 5rem;
  --ds-space-spacing-24: 6rem;
  --ds-space-spacing-28: 7rem;
  --ds-space-spacing-32: 8rem;
  --ds-space-spacing-36: 9rem;
  --ds-space-spacing-40: 10rem;
  --ds-space-spacing-44: 11rem;
  --ds-space-spacing-48: 12rem;
  --ds-space-spacing-52: 13rem;
  --ds-space-spacing-56: 14rem;
  --ds-space-spacing-60: 15rem;
  --ds-space-spacing-64: 16rem;
  --ds-space-spacing-72: 18rem;
  --ds-space-spacing-80: 20rem;
  --ds-space-spacing-96: 24rem;
}