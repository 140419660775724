/**
 * This file was generated automatically by Supernova.io and should not be changed manually.
 * To modify the format or content of this file, please contact your design system team. 
 */

:root {
  --ds-border-radius-border-radius-sm: var(--ds-dimension-radius-rounded-sm);
  --ds-border-radius-border-radius-default: var(--ds-dimension-radius-rounded-md);
  --ds-border-radius-border-radius-md: var(--ds-dimension-radius-rounded-md);
  --ds-border-radius-border-radius-lg: var(--ds-dimension-radius-rounded-lg);
  --ds-border-radius-border-radius-xl: var(--ds-dimension-radius-rounded-xl);
  --ds-border-radius-border-radius-full: var(--ds-dimension-radius-rounded-full);
}