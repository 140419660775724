/**
 * This file was generated automatically by Supernova.io and should not be changed manually.
 * To modify the format or content of this file, please contact your design system team. 
 */

:root {
  --ds-typography-text-extra-small-leading-normal-regular: 400 var(--ds-font-size-typography-base-sizes-extra-small-font-size)/var(--ds-line-height-typography-base-sizes-extra-small-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-extra-small-leading-normal-medium: 500 var(--ds-font-size-typography-base-sizes-extra-small-font-size)/var(--ds-line-height-typography-base-sizes-extra-small-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-extra-small-leading-normal-semibold: 600 var(--ds-font-size-typography-base-sizes-extra-small-font-size)/var(--ds-line-height-typography-base-sizes-extra-small-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-extra-small-leading-none-regular: 400 var(--ds-font-size-typography-base-sizes-extra-small-font-size)/100% var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-extra-small-leading-none-medium: 500 var(--ds-font-size-typography-base-sizes-extra-small-font-size)/100% var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-small-leading-normal-regular: 400 var(--ds-font-size-typography-base-sizes-small-font-size)/var(--ds-line-height-typography-base-sizes-small-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-small-leading-normal-medium: 500 var(--ds-font-size-typography-base-sizes-small-font-size)/var(--ds-line-height-typography-base-sizes-small-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-small-leading-normal-semibold: 600 var(--ds-font-size-typography-base-sizes-small-font-size)/var(--ds-line-height-typography-base-sizes-small-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-small-leading-normal-bold: 700 var(--ds-font-size-typography-base-sizes-small-font-size)/var(--ds-line-height-typography-base-sizes-small-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-small-leading-normal-underlined: 400 var(--ds-font-size-typography-base-sizes-small-font-size)/var(--ds-line-height-typography-base-sizes-small-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-small-leading-none-regular: 400 var(--ds-font-size-typography-base-sizes-small-font-size)/100% var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-small-leading-none-medium: 500 var(--ds-font-size-typography-base-sizes-small-font-size)/100% var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-small-leading-none-semibold: 600 var(--ds-font-size-typography-base-sizes-small-font-size)/100% var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-small-leading-none-underlined: 400 var(--ds-font-size-typography-base-sizes-small-font-size)/100% var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-base-leading-normal-regular: 400 var(--ds-font-size-typography-base-sizes-base-font-size)/var(--ds-line-height-typography-base-sizes-base-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-base-leading-normal-medium: 500 var(--ds-font-size-typography-base-sizes-base-font-size)/var(--ds-line-height-typography-base-sizes-base-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-base-leading-normal-semibold: 600 var(--ds-font-size-typography-base-sizes-base-font-size)/var(--ds-line-height-typography-base-sizes-base-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-base-leading-none-medium: 500 var(--ds-font-size-typography-base-sizes-base-font-size)/100% var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-large-leading-normal-regular: 400 var(--ds-font-size-typography-base-sizes-large-font-size)/var(--ds-line-height-typography-base-sizes-large-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-large-leading-normal-medium: 500 var(--ds-font-size-typography-base-sizes-large-font-size)/var(--ds-line-height-typography-base-sizes-large-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-large-leading-normal-semibold: 600 var(--ds-dimension-typography-typography-components-h3-font-size)/var(--ds-dimension-typography-typography-components-h3-line-height) var(--ds-string-typography-typography-components-h3-font-family);
  --ds-typography-text-extra-large-leading-normal-semibold: 600 var(--ds-font-size-typography-base-sizes-xlarge-font-size)/var(--ds-line-height-typography-base-sizes-xlarge-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-extra-large-leading-normal-bold: 700 var(--ds-font-size-typography-base-sizes-xlarge-font-size)/var(--ds-line-height-typography-base-sizes-xlarge-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-2x-large-leading-normal-semibold: 600 var(--ds-font-size-typography-base-sizes-2x-large-font-size)/var(--ds-line-height-typography-base-sizes-2x-large-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-2x-large-leading-normal-bold: 700 var(--ds-font-size-typography-base-sizes-2x-large-font-size)/var(--ds-line-height-typography-base-sizes-2x-large-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-3x-large-leading-normal-semibold: 600 var(--ds-font-size-typography-base-sizes-3x-large-font-size)/var(--ds-line-height-typography-base-sizes-3x-large-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-2x-large-leading-none-bold: 700 var(--ds-font-size-typography-base-sizes-2x-large-font-size)/100% var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-large-leading-none-semibold: 600 var(--ds-font-size-typography-base-sizes-large-font-size)/100% var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-4x-large-leading-normal-bold: 700 var(--ds-font-size-typography-base-sizes-4x-large-font-size)/var(--ds-line-height-typography-base-sizes-4x-large-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-extra-large-leading-none-semibold: 600 1.25rem/100% "Inter";
  --ds-typography-text-extra-large-leading-none-bold: 700 var(--ds-font-size-typography-base-sizes-xlarge-font-size)/100% var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-3x-large-leading-none-bold: 700 var(--ds-font-size-typography-base-sizes-3x-large-font-size)/100% var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-3x-large-leading-normal-bold: 700 var(--ds-font-size-typography-base-sizes-3x-large-font-size)/var(--ds-line-height-typography-base-sizes-3x-large-line-height) var(--ds-font-family-typography-font-family-font-sans);
  --ds-typography-text-small-leading-normal-underlined-medium: 500 var(--ds-font-size-typography-base-sizes-small-font-size)/var(--ds-line-height-typography-base-sizes-small-line-height) var(--ds-font-family-typography-font-family-font-sans);
}