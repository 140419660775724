/**
 * This file was generated automatically by Supernova.io and should not be changed manually.
 * To modify the format or content of this file, please contact your design system team. 
 */

:root {
  --ds-shadow-shadow-sm: 0rem 0.063rem 0.125rem 0rem rgba(0, 0, 0, 0.05);
  --ds-shadow-shadow-base: 0rem 0.063rem 0.125rem 0rem rgba(0, 0, 0, 0.06), 0rem 0.063rem 0.188rem 0rem rgba(0, 0, 0, 0.1);
  --ds-shadow-shadow-md: 0rem 0.125rem 0.25rem -0.062rem rgba(0, 0, 0, 0.06), 0rem 0.25rem 0.375rem -0.062rem rgba(0, 0, 0, 0.1);
  --ds-shadow-shadow-lg: 0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05), 0rem 0.625rem 0.938rem -0.187rem rgba(0, 0, 0, 0.1);
  --ds-shadow-shadow-xl: 0rem 0.625rem 0.625rem -0.312rem rgba(0, 0, 0, 0.04), 0rem 1.25rem 1.563rem -0.312rem rgba(0, 0, 0, 0.1);
}