/**
 * This file was generated automatically by Supernova.io and should not be changed manually.
 * To modify the format or content of this file, please contact your design system team. 
 */

:root {
  --ds-line-height-typography-base-sizes-extra-small-line-height: var(--ds-dimension-font-line-height-leading-4);
  --ds-line-height-typography-base-sizes-small-line-height: var(--ds-dimension-font-line-height-leading-5);
  --ds-line-height-typography-base-sizes-base-line-height: var(--ds-dimension-font-line-height-leading-6);
  --ds-line-height-typography-base-sizes-large-line-height: var(--ds-dimension-font-line-height-leading-7);
  --ds-line-height-typography-base-sizes-xlarge-line-height: var(--ds-dimension-font-line-height-leading-7);
  --ds-line-height-typography-base-sizes-2x-large-line-height: var(--ds-dimension-font-line-height-leading-8);
  --ds-line-height-typography-base-sizes-3x-large-line-height: var(--ds-dimension-font-line-height-leading-9);
  --ds-line-height-typography-base-sizes-4x-large-line-height: var(--ds-dimension-font-line-height-leading-10);
}