@import './vendors/tailwind.css';

/* Design System */
@import './design-system/variables.css';

/* Base Theme and Globals */
@import './base/theme.css';
@import './base/globals.css';

/* Vendors */
@import './vendors/react-bottom-sheet.css';
