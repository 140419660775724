/**
 * This file was generated automatically by Supernova.io and should not be changed manually.
 * To modify the format or content of this file, please contact your design system team. 
 */

:root {
  --ds-dimension-radius-rounded-none: 0rem;
  --ds-dimension-radius-rounded-sm: 0.125rem;
  --ds-dimension-radius-rounded: 0.25rem;
  --ds-dimension-radius-rounded-md: 0.375rem;
  --ds-dimension-radius-rounded-lg: 0.5rem;
  --ds-dimension-radius-rounded-xl: 0.75rem;
  --ds-dimension-radius-rounded-2xl: 1rem;
  --ds-dimension-radius-rounded-3xl: 1.5rem;
  --ds-dimension-radius-rounded-4xl: 2rem;
  --ds-dimension-radius-rounded-full: 624.938rem;
  --ds-dimension-opacity-opacity-0: 0rem;
  --ds-dimension-opacity-opacity-5: 0.313rem;
  --ds-dimension-opacity-opacity-10: 0.625rem;
  --ds-dimension-opacity-opacity-15: 0.938rem;
  --ds-dimension-opacity-opacity-20: 1.25rem;
  --ds-dimension-opacity-opacity-25: 1.563rem;
  --ds-dimension-opacity-opacity-30: 1.875rem;
  --ds-dimension-opacity-opacity-35: 2.188rem;
  --ds-dimension-opacity-opacity-40: 2.5rem;
  --ds-dimension-opacity-opacity-45: 2.813rem;
  --ds-dimension-opacity-opacity-50: 3.125rem;
  --ds-dimension-opacity-opacity-55: 3.438rem;
  --ds-dimension-opacity-opacity-60: 3.75rem;
  --ds-dimension-opacity-opacity-65: 4.063rem;
  --ds-dimension-opacity-opacity-70: 4.375rem;
  --ds-dimension-opacity-opacity-75: 4.688rem;
  --ds-dimension-opacity-opacity-80: 5rem;
  --ds-dimension-opacity-opacity-85: 5.313rem;
  --ds-dimension-opacity-opacity-90: 5.625rem;
  --ds-dimension-opacity-opacity-95: 5.938rem;
  --ds-dimension-opacity-opacity-100: 6.25rem;
  --ds-dimension-blur-blur-none: 0rem;
  --ds-dimension-blur-blur-sm: 0.25rem;
  --ds-dimension-blur-blur: 0.5rem;
  --ds-dimension-blur-blur-md: 0.75rem;
  --ds-dimension-blur-blur-lg: 1rem;
  --ds-dimension-blur-blur-xl: 1.5rem;
  --ds-dimension-blur-blur-2xl: 2.5rem;
  --ds-dimension-blur-blur-3xl: 4rem;
  --ds-dimension-font-weight-thin: 6.25rem;
  --ds-dimension-font-weight-extralight: 12.5rem;
  --ds-dimension-font-weight-light: 18.75rem;
  --ds-dimension-font-weight-normal: 25rem;
  --ds-dimension-font-weight-medium: 31.25rem;
  --ds-dimension-font-weight-semibold: 37.5rem;
  --ds-dimension-font-weight-bold: 43.75rem;
  --ds-dimension-font-weight-extrabold: 50rem;
  --ds-dimension-font-weight-black: 56.25rem;
  --ds-dimension-font-letter-spacing-tighter: -0.05rem;
  --ds-dimension-font-letter-spacing-tight: -0.025rem;
  --ds-dimension-font-letter-spacing-normal: 0rem;
  --ds-dimension-font-letter-spacing-wide: 0.025rem;
  --ds-dimension-font-letter-spacing-wider: 0.05rem;
  --ds-dimension-font-letter-spacing-widest: 0.1rem;
  --ds-dimension-font-line-height-leading-3: 0.75rem;
  --ds-dimension-font-line-height-leading-4: 1rem;
  --ds-dimension-font-line-height-leading-5: 1.25rem;
  --ds-dimension-font-line-height-leading-6: 1.5rem;
  --ds-dimension-font-line-height-leading-7: 1.75rem;
  --ds-dimension-font-line-height-leading-8: 2rem;
  --ds-dimension-font-line-height-leading-9: 2.25rem;
  --ds-dimension-font-line-height-leading-10: 2.5rem;
  --ds-dimension-font-size-text-xs: 0.75rem;
  --ds-dimension-font-size-text-sm: 0.875rem;
  --ds-dimension-font-size-text-base: 1rem;
  --ds-dimension-font-size-text-lg: 1.125rem;
  --ds-dimension-font-size-text-xl: 1.25rem;
  --ds-dimension-font-size-text-2xl: 1.5rem;
  --ds-dimension-font-size-text-3xl: 1.875rem;
  --ds-dimension-font-size-text-4xl: 2.25rem;
  --ds-dimension-font-size-text-5xl: 3rem;
  --ds-dimension-font-size-text-6xl: 3.75rem;
  --ds-dimension-font-size-text-7xl: 4.5rem;
  --ds-dimension-font-size-text-8xl: 6rem;
  --ds-dimension-font-size-text-9xl: 8rem;
  --ds-dimension-typography-typography-components-h1-font-size: var(--ds-dimension-font-size-text-4xl);
  --ds-dimension-typography-typography-components-h1-font-size-lg: var(--ds-dimension-font-size-text-5xl);
  --ds-dimension-typography-typography-components-h1-line-height: var(--ds-dimension-font-line-height-leading-10);
  --ds-dimension-typography-typography-components-h1-font-weight: var(--ds-dimension-font-weight-extrabold);
  --ds-dimension-typography-typography-components-h1-letter-spacing: var(--ds-dimension-font-letter-spacing-tight);
  --ds-dimension-typography-typography-components-h2-font-size: var(--ds-dimension-font-size-text-3xl);
  --ds-dimension-typography-typography-components-h2-line-height: var(--ds-dimension-font-line-height-leading-9);
  --ds-dimension-typography-typography-components-h2-font-weight: var(--ds-dimension-font-weight-semibold);
  --ds-dimension-typography-typography-components-h2-letter-spacing: var(--ds-dimension-font-letter-spacing-tight);
  --ds-dimension-typography-typography-components-h3-font-size: var(--ds-dimension-font-size-text-2xl);
  --ds-dimension-typography-typography-components-h3-line-height: var(--ds-dimension-font-line-height-leading-8);
  --ds-dimension-typography-typography-components-h3-font-weight: var(--ds-dimension-font-weight-semibold);
  --ds-dimension-typography-typography-components-h3-letter-spacing: var(--ds-dimension-font-letter-spacing-tight);
  --ds-dimension-typography-typography-components-h4-font-size: var(--ds-dimension-font-size-text-xl);
  --ds-dimension-typography-typography-components-h4-line-height: var(--ds-dimension-font-line-height-leading-7);
  --ds-dimension-typography-typography-components-h4-font-weight: var(--ds-dimension-font-weight-semibold);
  --ds-dimension-typography-typography-components-h4-letter-spacing: var(--ds-dimension-font-letter-spacing-tight);
  --ds-dimension-typography-typography-components-p-font-size: var(--ds-dimension-font-size-text-base);
  --ds-dimension-typography-typography-components-p-line-height: var(--ds-dimension-font-line-height-leading-7);
  --ds-dimension-typography-typography-components-p-font-weight: var(--ds-dimension-font-weight-normal);
  --ds-dimension-typography-typography-components-p-letter-spacing: var(--ds-dimension-font-letter-spacing-normal);
  --ds-dimension-typography-typography-components-blockquote-font-size: var(--ds-dimension-font-size-text-base);
  --ds-dimension-typography-typography-components-blockquote-line-height: var(--ds-dimension-font-line-height-leading-6);
  --ds-dimension-typography-typography-components-blockquote-font-weight: var(--ds-dimension-font-weight-normal);
  --ds-dimension-typography-typography-components-blockquote-letter-spacing: var(--ds-dimension-font-letter-spacing-normal);
  --ds-dimension-typography-typography-components-list-font-size: var(--ds-dimension-font-size-text-base);
  --ds-dimension-typography-typography-components-list-line-height: var(--ds-dimension-font-line-height-leading-7);
  --ds-dimension-typography-typography-components-list-font-weight: var(--ds-dimension-font-weight-normal);
  --ds-dimension-typography-typography-components-list-letter-spacing: var(--ds-dimension-font-letter-spacing-normal);
  --ds-dimension-typography-typography-components-inline-code-font-size: var(--ds-dimension-font-size-text-sm);
  --ds-dimension-typography-typography-components-inline-code-line-height: var(--ds-dimension-font-line-height-leading-5);
  --ds-dimension-typography-typography-components-inline-code-font-weight: var(--ds-dimension-font-weight-semibold);
  --ds-dimension-typography-typography-components-inline-code-letter-spacing: var(--ds-dimension-font-letter-spacing-normal);
  --ds-dimension-typography-typography-components-lead-font-size: var(--ds-dimension-font-size-text-xl);
  --ds-dimension-typography-typography-components-lead-line-height: var(--ds-dimension-font-line-height-leading-7);
  --ds-dimension-typography-typography-components-lead-font-weight: var(--ds-dimension-font-weight-normal);
  --ds-dimension-typography-typography-components-lead-letter-spacing: var(--ds-dimension-font-letter-spacing-normal);
  --ds-dimension-typography-typography-components-large-font-size: var(--ds-dimension-font-size-text-lg);
  --ds-dimension-typography-typography-components-large-line-height: var(--ds-dimension-font-line-height-leading-7);
  --ds-dimension-typography-typography-components-large-font-weight: var(--ds-dimension-font-weight-semibold);
  --ds-dimension-typography-typography-components-large-letter-spacing: var(--ds-dimension-font-letter-spacing-normal);
  --ds-dimension-typography-typography-components-small-font-size: var(--ds-dimension-font-size-text-sm);
  --ds-dimension-typography-typography-components-small-line-height: var(--ds-dimension-font-size-text-sm);
  --ds-dimension-typography-typography-components-small-font-weight: var(--ds-dimension-font-weight-medium);
  --ds-dimension-typography-typography-components-small-letter-spacing: var(--ds-dimension-font-line-height-leading-4);
  --ds-dimension-typography-typography-components-table-font-size: var(--ds-dimension-font-size-text-base);
  --ds-dimension-typography-typography-components-table-font-weight: var(--ds-dimension-font-weight-normal);
  --ds-dimension-typography-typography-components-table-font-weight-bold: var(--ds-dimension-font-weight-bold);
  --ds-dimension-typography-typography-components-table-letter-spacing: var(--ds-dimension-font-letter-spacing-normal);
}