/**
 * This file was generated automatically by Supernova.io and should not be changed manually.
 * To modify the format or content of this file, please contact your design system team. 
 */

:root {
  --ds-border-width-border-width-border-0: 0rem;
  --ds-border-width-border-width-border-1: 0.063rem;
  --ds-border-width-border-width-border-2: 0.125rem;
  --ds-border-width-border-width-border-4: 0.25rem;
  --ds-border-width-border-width-border-8: 0.5rem;
}