/**
 * This file was generated automatically by Supernova.io and should not be changed manually.
 * To modify the format or content of this file, please contact your design system team. 
 */

:root {
  --ds-font-size-typography-base-sizes-extra-small-font-size: var(--ds-dimension-font-size-text-xs);
  --ds-font-size-typography-base-sizes-small-font-size: var(--ds-dimension-font-size-text-sm);
  --ds-font-size-typography-base-sizes-base-font-size: var(--ds-dimension-font-size-text-base);
  --ds-font-size-typography-base-sizes-large-font-size: var(--ds-dimension-font-size-text-lg);
  --ds-font-size-typography-base-sizes-xlarge-font-size: var(--ds-dimension-font-size-text-xl);
  --ds-font-size-typography-base-sizes-2x-large-font-size: var(--ds-dimension-font-size-text-2xl);
  --ds-font-size-typography-base-sizes-3x-large-font-size: var(--ds-dimension-font-size-text-3xl);
  --ds-font-size-typography-base-sizes-4x-large-font-size: var(--ds-dimension-font-size-text-4xl);
}