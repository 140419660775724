/**
 * This file was generated automatically by Supernova.io and should not be changed manually.
 * To modify the format or content of this file, please contact your design system team. 
 */

:root {
  --ds-string-font-style-italic: "italic";
  --ds-string-font-style-not-italic: "normal";
  --ds-string-typography-typography-components-h1-font-family: var(--ds-font-family-typography-font-family-font-sans);
  --ds-string-typography-typography-components-h2-font-family: var(--ds-font-family-typography-font-family-font-sans);
  --ds-string-typography-typography-components-h3-font-family: var(--ds-font-family-typography-font-family-font-sans);
  --ds-string-typography-typography-components-h4-font-family: var(--ds-font-family-typography-font-family-font-sans);
  --ds-string-typography-typography-components-p-font-family: var(--ds-font-family-typography-font-family-font-sans);
  --ds-string-typography-typography-components-blockquote-font-family: var(--ds-font-family-typography-font-family-font-sans);
  --ds-string-typography-typography-components-blockquote-font-style: var(--ds-string-font-style-italic);
  --ds-string-typography-typography-components-list-font-family: var(--ds-font-family-typography-font-family-font-sans);
  --ds-string-typography-typography-components-inline-code-font-family: var(--ds-font-family-typography-font-family-font-mono);
  --ds-string-typography-typography-components-lead-font-family: var(--ds-font-family-typography-font-family-font-sans);
  --ds-string-typography-typography-components-large-font-family: var(--ds-font-family-typography-font-family-font-sans);
  --ds-string-typography-typography-components-small-font-family: var(--ds-font-family-typography-font-family-font-sans);
  --ds-string-typography-typography-components-table-font-family: var(--ds-font-family-typography-font-family-font-sans);
}