/**
 * This file was generated automatically by Supernova.io and should not be changed manually.
 * To modify the format or content of this file, please contact your design system team. 
 */

:root {
  --ds-color-tailwind-colors-slate-50: rgba(248, 250, 252, 1);
  --ds-color-tailwind-colors-slate-100: rgba(241, 245, 249, 1);
  --ds-color-tailwind-colors-slate-200: rgba(226, 232, 240, 1);
  --ds-color-tailwind-colors-slate-300: rgba(202, 213, 226, 1);
  --ds-color-tailwind-colors-slate-400: rgba(144, 161, 185, 1);
  --ds-color-tailwind-colors-slate-500: rgba(98, 116, 142, 1);
  --ds-color-tailwind-colors-slate-600: rgba(69, 85, 108, 1);
  --ds-color-tailwind-colors-slate-700: rgba(49, 65, 88, 1);
  --ds-color-tailwind-colors-base-black: rgba(0, 0, 0, 1);
  --ds-color-tailwind-colors-slate-800: rgba(29, 41, 61, 1);
  --ds-color-tailwind-colors-base-white: rgba(255, 255, 255, 1);
  --ds-color-tailwind-colors-slate-900: rgba(15, 23, 43, 1);
  --ds-color-tailwind-colors-base-transparent: rgba(255, 255, 255, 0);
  --ds-color-tailwind-colors-slate-950: rgba(2, 6, 24, 1);
  --ds-color-custom-colors-iris-50: rgba(240, 244, 253, 1);
  --ds-color-custom-colors-iris-100: rgba(227, 234, 252, 1);
  --ds-color-custom-colors-iris-200: rgba(205, 215, 248, 1);
  --ds-color-custom-colors-iris-300: rgba(174, 190, 243, 1);
  --ds-color-custom-colors-iris-400: rgba(141, 155, 236, 1);
  --ds-color-custom-colors-iris-500: rgba(113, 122, 227, 1);
  --ds-color-custom-colors-iris-600: rgba(91, 91, 214, 1);
  --ds-color-custom-colors-iris-700: rgba(73, 71, 187, 1);
  --ds-color-custom-colors-iris-800: rgba(60, 60, 151, 1);
  --ds-color-custom-colors-iris-900: rgba(54, 55, 121, 1);
  --ds-color-custom-colors-iris-950: rgba(32, 32, 70, 1);
  --ds-color-tailwind-colors-gray-50: rgba(249, 250, 251, 1);
  --ds-color-tailwind-colors-gray-100: rgba(243, 244, 246, 1);
  --ds-color-tailwind-colors-gray-200: rgba(229, 231, 235, 1);
  --ds-color-tailwind-colors-gray-300: rgba(209, 213, 220, 1);
  --ds-color-tailwind-colors-gray-400: rgba(153, 161, 175, 1);
  --ds-color-tailwind-colors-gray-500: rgba(106, 114, 130, 1);
  --ds-color-tailwind-colors-gray-600: rgba(74, 85, 101, 1);
  --ds-color-tailwind-colors-gray-700: rgba(54, 65, 83, 1);
  --ds-color-tailwind-colors-gray-800: rgba(30, 41, 57, 1);
  --ds-color-tailwind-colors-gray-900: rgba(16, 24, 40, 1);
  --ds-color-tailwind-colors-gray-950: rgba(3, 7, 18, 1);
  --ds-color-tailwind-colors-zinc-50: rgba(250, 250, 250, 1);
  --ds-color-tailwind-colors-zinc-100: rgba(244, 244, 245, 1);
  --ds-color-tailwind-colors-zinc-200: rgba(228, 228, 231, 1);
  --ds-color-tailwind-colors-zinc-300: rgba(212, 212, 216, 1);
  --ds-color-tailwind-colors-zinc-400: rgba(159, 159, 169, 1);
  --ds-color-tailwind-colors-zinc-500: rgba(113, 113, 123, 1);
  --ds-color-tailwind-colors-zinc-600: rgba(82, 82, 92, 1);
  --ds-color-tailwind-colors-zinc-700: rgba(63, 63, 70, 1);
  --ds-color-tailwind-colors-zinc-800: rgba(39, 39, 42, 1);
  --ds-color-tailwind-colors-zinc-900: rgba(24, 24, 27, 1);
  --ds-color-tailwind-colors-zinc-950: rgba(9, 9, 11, 1);
  --ds-color-tailwind-colors-neutral-50: rgba(250, 250, 250, 1);
  --ds-color-tailwind-colors-neutral-100: rgba(245, 245, 245, 1);
  --ds-color-tailwind-colors-neutral-200: rgba(229, 229, 229, 1);
  --ds-color-tailwind-colors-neutral-300: rgba(212, 212, 212, 1);
  --ds-color-tailwind-colors-neutral-400: rgba(161, 161, 161, 1);
  --ds-color-tailwind-colors-neutral-500: rgba(115, 115, 115, 1);
  --ds-color-tailwind-colors-neutral-600: rgba(82, 82, 82, 1);
  --ds-color-tailwind-colors-neutral-700: rgba(64, 64, 64, 1);
  --ds-color-tailwind-colors-neutral-800: rgba(38, 38, 38, 1);
  --ds-color-tailwind-colors-neutral-900: rgba(23, 23, 23, 1);
  --ds-color-tailwind-colors-neutral-950: rgba(10, 10, 10, 1);
  --ds-color-tailwind-colors-stone-50: rgba(250, 250, 249, 1);
  --ds-color-tailwind-colors-stone-100: rgba(245, 245, 244, 1);
  --ds-color-tailwind-colors-stone-200: rgba(231, 229, 228, 1);
  --ds-color-tailwind-colors-stone-300: rgba(214, 211, 209, 1);
  --ds-color-tailwind-colors-stone-400: rgba(166, 160, 155, 1);
  --ds-color-tailwind-colors-stone-500: rgba(121, 113, 107, 1);
  --ds-color-tailwind-colors-stone-600: rgba(87, 83, 77, 1);
  --ds-color-tailwind-colors-stone-700: rgba(68, 64, 59, 1);
  --ds-color-tailwind-colors-stone-800: rgba(41, 37, 36, 1);
  --ds-color-tailwind-colors-stone-900: rgba(28, 25, 23, 1);
  --ds-color-tailwind-colors-stone-950: rgba(12, 10, 9, 1);
  --ds-color-tailwind-colors-red-50: rgba(254, 242, 242, 1);
  --ds-color-tailwind-colors-red-100: rgba(255, 226, 226, 1);
  --ds-color-tailwind-colors-red-200: rgba(255, 201, 201, 1);
  --ds-color-tailwind-colors-red-300: rgba(255, 162, 162, 1);
  --ds-color-tailwind-colors-red-400: rgba(255, 100, 103, 1);
  --ds-color-tailwind-colors-red-500: rgba(251, 44, 54, 1);
  --ds-color-tailwind-colors-red-600: rgba(231, 0, 11, 1);
  --ds-color-tailwind-colors-red-700: rgba(193, 0, 7, 1);
  --ds-color-tailwind-colors-red-800: rgba(159, 7, 18, 1);
  --ds-color-tailwind-colors-red-900: rgba(130, 24, 26, 1);
  --ds-color-tailwind-colors-red-950: rgba(70, 8, 9, 1);
  --ds-color-tailwind-colors-orange-50: rgba(255, 247, 237, 1);
  --ds-color-tailwind-colors-orange-100: rgba(255, 237, 212, 1);
  --ds-color-tailwind-colors-orange-200: rgba(255, 214, 168, 1);
  --ds-color-tailwind-colors-orange-300: rgba(255, 184, 106, 1);
  --ds-color-tailwind-colors-orange-400: rgba(255, 137, 4, 1);
  --ds-color-tailwind-colors-orange-500: rgba(255, 105, 0, 1);
  --ds-color-tailwind-colors-orange-600: rgba(245, 74, 0, 1);
  --ds-color-tailwind-colors-orange-700: rgba(202, 53, 0, 1);
  --ds-color-tailwind-colors-orange-800: rgba(159, 45, 0, 1);
  --ds-color-tailwind-colors-orange-900: rgba(126, 42, 12, 1);
  --ds-color-tailwind-colors-orange-950: rgba(68, 19, 6, 1);
  --ds-color-tailwind-colors-amber-50: rgba(255, 251, 235, 1);
  --ds-color-tailwind-colors-amber-100: rgba(254, 243, 198, 1);
  --ds-color-tailwind-colors-amber-200: rgba(254, 230, 133, 1);
  --ds-color-tailwind-colors-amber-300: rgba(255, 210, 48, 1);
  --ds-color-tailwind-colors-amber-400: rgba(255, 186, 0, 1);
  --ds-color-tailwind-colors-amber-500: rgba(253, 154, 0, 1);
  --ds-color-tailwind-colors-amber-600: rgba(225, 113, 0, 1);
  --ds-color-tailwind-colors-amber-700: rgba(187, 77, 0, 1);
  --ds-color-tailwind-colors-amber-800: rgba(151, 60, 0, 1);
  --ds-color-tailwind-colors-amber-900: rgba(123, 51, 6, 1);
  --ds-color-tailwind-colors-amber-950: rgba(70, 25, 1, 1);
  --ds-color-tailwind-colors-yellow-50: rgba(254, 252, 232, 1);
  --ds-color-tailwind-colors-yellow-100: rgba(254, 249, 194, 1);
  --ds-color-tailwind-colors-yellow-200: rgba(255, 240, 133, 1);
  --ds-color-tailwind-colors-yellow-300: rgba(255, 223, 32, 1);
  --ds-color-tailwind-colors-yellow-400: rgba(252, 200, 0, 1);
  --ds-color-tailwind-colors-yellow-500: rgba(239, 177, 0, 1);
  --ds-color-tailwind-colors-yellow-600: rgba(208, 135, 0, 1);
  --ds-color-tailwind-colors-yellow-700: rgba(166, 95, 0, 1);
  --ds-color-tailwind-colors-yellow-800: rgba(137, 75, 0, 1);
  --ds-color-tailwind-colors-yellow-900: rgba(115, 62, 10, 1);
  --ds-color-tailwind-colors-yellow-950: rgba(67, 32, 4, 1);
  --ds-color-tailwind-colors-lime-50: rgba(247, 254, 231, 1);
  --ds-color-tailwind-colors-lime-100: rgba(236, 252, 202, 1);
  --ds-color-tailwind-colors-lime-200: rgba(216, 249, 153, 1);
  --ds-color-tailwind-colors-lime-300: rgba(187, 244, 81, 1);
  --ds-color-tailwind-colors-lime-400: rgba(154, 230, 0, 1);
  --ds-color-tailwind-colors-lime-500: rgba(124, 207, 0, 1);
  --ds-color-tailwind-colors-lime-600: rgba(94, 165, 0, 1);
  --ds-color-tailwind-colors-lime-700: rgba(73, 125, 0, 1);
  --ds-color-tailwind-colors-lime-800: rgba(61, 99, 0, 1);
  --ds-color-tailwind-colors-lime-900: rgba(53, 83, 14, 1);
  --ds-color-tailwind-colors-lime-950: rgba(25, 46, 3, 1);
  --ds-color-tailwind-colors-green-50: rgba(240, 253, 244, 1);
  --ds-color-tailwind-colors-green-100: rgba(220, 252, 231, 1);
  --ds-color-tailwind-colors-green-200: rgba(185, 248, 207, 1);
  --ds-color-tailwind-colors-green-300: rgba(123, 241, 168, 1);
  --ds-color-tailwind-colors-green-400: rgba(5, 223, 114, 1);
  --ds-color-tailwind-colors-green-500: rgba(0, 201, 81, 1);
  --ds-color-tailwind-colors-green-600: rgba(0, 166, 62, 1);
  --ds-color-tailwind-colors-green-700: rgba(0, 130, 54, 1);
  --ds-color-tailwind-colors-green-800: rgba(1, 102, 48, 1);
  --ds-color-tailwind-colors-green-900: rgba(13, 84, 43, 1);
  --ds-color-tailwind-colors-green-950: rgba(5, 46, 22, 1);
  --ds-color-tailwind-colors-emerald-50: rgba(236, 253, 245, 1);
  --ds-color-tailwind-colors-emerald-100: rgba(208, 250, 229, 1);
  --ds-color-tailwind-colors-emerald-200: rgba(164, 244, 207, 1);
  --ds-color-tailwind-colors-emerald-300: rgba(94, 233, 181, 1);
  --ds-color-tailwind-colors-emerald-400: rgba(0, 212, 146, 1);
  --ds-color-tailwind-colors-emerald-500: rgba(0, 188, 125, 1);
  --ds-color-tailwind-colors-emerald-600: rgba(0, 153, 102, 1);
  --ds-color-tailwind-colors-emerald-700: rgba(0, 122, 85, 1);
  --ds-color-tailwind-colors-emerald-800: rgba(0, 96, 69, 1);
  --ds-color-tailwind-colors-emerald-900: rgba(0, 79, 59, 1);
  --ds-color-tailwind-colors-emerald-950: rgba(0, 44, 34, 1);
  --ds-color-tailwind-colors-teal-50: rgba(240, 253, 250, 1);
  --ds-color-tailwind-colors-teal-100: rgba(203, 251, 241, 1);
  --ds-color-tailwind-colors-teal-200: rgba(150, 247, 228, 1);
  --ds-color-tailwind-colors-teal-300: rgba(70, 236, 213, 1);
  --ds-color-tailwind-colors-teal-400: rgba(0, 213, 190, 1);
  --ds-color-tailwind-colors-teal-500: rgba(0, 187, 167, 1);
  --ds-color-tailwind-colors-teal-600: rgba(0, 150, 137, 1);
  --ds-color-tailwind-colors-teal-700: rgba(0, 120, 111, 1);
  --ds-color-tailwind-colors-teal-800: rgba(0, 95, 90, 1);
  --ds-color-tailwind-colors-teal-900: rgba(11, 79, 74, 1);
  --ds-color-tailwind-colors-teal-950: rgba(2, 47, 46, 1);
  --ds-color-tailwind-colors-cyan-50: rgba(236, 254, 255, 1);
  --ds-color-tailwind-colors-cyan-100: rgba(206, 250, 254, 1);
  --ds-color-tailwind-colors-cyan-200: rgba(162, 244, 253, 1);
  --ds-color-tailwind-colors-cyan-300: rgba(83, 234, 253, 1);
  --ds-color-tailwind-colors-cyan-400: rgba(0, 211, 242, 1);
  --ds-color-tailwind-colors-cyan-500: rgba(0, 184, 219, 1);
  --ds-color-tailwind-colors-cyan-600: rgba(0, 146, 184, 1);
  --ds-color-tailwind-colors-cyan-700: rgba(0, 117, 149, 1);
  --ds-color-tailwind-colors-cyan-800: rgba(0, 95, 120, 1);
  --ds-color-tailwind-colors-cyan-900: rgba(16, 78, 100, 1);
  --ds-color-tailwind-colors-cyan-950: rgba(5, 51, 69, 1);
  --ds-color-tailwind-colors-sky-50: rgba(240, 249, 255, 1);
  --ds-color-tailwind-colors-sky-100: rgba(223, 242, 254, 1);
  --ds-color-tailwind-colors-sky-200: rgba(184, 230, 254, 1);
  --ds-color-tailwind-colors-sky-300: rgba(116, 212, 255, 1);
  --ds-color-tailwind-colors-sky-400: rgba(0, 188, 255, 1);
  --ds-color-tailwind-colors-sky-500: rgba(0, 166, 244, 1);
  --ds-color-tailwind-colors-sky-600: rgba(0, 132, 209, 1);
  --ds-color-tailwind-colors-sky-700: rgba(0, 105, 168, 1);
  --ds-color-tailwind-colors-sky-800: rgba(0, 89, 138, 1);
  --ds-color-tailwind-colors-sky-900: rgba(2, 74, 112, 1);
  --ds-color-tailwind-colors-sky-950: rgba(5, 47, 74, 1);
  --ds-color-tailwind-colors-blue-50: rgba(239, 246, 255, 1);
  --ds-color-tailwind-colors-blue-100: rgba(219, 234, 254, 1);
  --ds-color-tailwind-colors-blue-200: rgba(190, 219, 255, 1);
  --ds-color-tailwind-colors-blue-300: rgba(142, 197, 255, 1);
  --ds-color-tailwind-colors-blue-400: rgba(81, 162, 255, 1);
  --ds-color-tailwind-colors-blue-500: rgba(43, 127, 255, 1);
  --ds-color-tailwind-colors-blue-600: rgba(21, 93, 252, 1);
  --ds-color-tailwind-colors-blue-700: rgba(20, 71, 230, 1);
  --ds-color-tailwind-colors-blue-800: rgba(25, 60, 184, 1);
  --ds-color-tailwind-colors-blue-900: rgba(28, 57, 142, 1);
  --ds-color-tailwind-colors-blue-950: rgba(22, 36, 86, 1);
  --ds-color-tailwind-colors-indigo-50: rgba(238, 242, 255, 1);
  --ds-color-tailwind-colors-indigo-100: rgba(224, 231, 255, 1);
  --ds-color-tailwind-colors-indigo-200: rgba(198, 210, 255, 1);
  --ds-color-tailwind-colors-indigo-300: rgba(163, 179, 255, 1);
  --ds-color-tailwind-colors-indigo-400: rgba(124, 134, 255, 1);
  --ds-color-tailwind-colors-indigo-500: rgba(97, 95, 255, 1);
  --ds-color-tailwind-colors-indigo-600: rgba(79, 57, 246, 1);
  --ds-color-tailwind-colors-indigo-700: rgba(67, 45, 215, 1);
  --ds-color-tailwind-colors-indigo-800: rgba(55, 42, 172, 1);
  --ds-color-tailwind-colors-indigo-900: rgba(49, 44, 133, 1);
  --ds-color-tailwind-colors-indigo-950: rgba(30, 26, 77, 1);
  --ds-color-tailwind-colors-violet-50: rgba(245, 243, 255, 1);
  --ds-color-tailwind-colors-violet-100: rgba(237, 233, 254, 1);
  --ds-color-tailwind-colors-violet-200: rgba(221, 214, 255, 1);
  --ds-color-tailwind-colors-violet-300: rgba(196, 180, 255, 1);
  --ds-color-tailwind-colors-violet-400: rgba(166, 132, 255, 1);
  --ds-color-tailwind-colors-violet-500: rgba(142, 81, 255, 1);
  --ds-color-tailwind-colors-violet-600: rgba(127, 34, 254, 1);
  --ds-color-tailwind-colors-violet-700: rgba(112, 8, 231, 1);
  --ds-color-tailwind-colors-violet-800: rgba(93, 14, 192, 1);
  --ds-color-tailwind-colors-violet-900: rgba(77, 23, 154, 1);
  --ds-color-tailwind-colors-violet-950: rgba(47, 13, 104, 1);
  --ds-color-tailwind-colors-purple-50: rgba(250, 245, 255, 1);
  --ds-color-tailwind-colors-purple-100: rgba(243, 232, 255, 1);
  --ds-color-tailwind-colors-purple-200: rgba(233, 212, 255, 1);
  --ds-color-tailwind-colors-purple-300: rgba(218, 178, 255, 1);
  --ds-color-tailwind-colors-purple-400: rgba(194, 122, 255, 1);
  --ds-color-tailwind-colors-purple-500: rgba(173, 70, 255, 1);
  --ds-color-tailwind-colors-purple-600: rgba(152, 16, 250, 1);
  --ds-color-tailwind-colors-purple-700: rgba(130, 0, 219, 1);
  --ds-color-tailwind-colors-purple-800: rgba(110, 17, 176, 1);
  --ds-color-tailwind-colors-purple-900: rgba(89, 22, 139, 1);
  --ds-color-tailwind-colors-purple-950: rgba(59, 7, 100, 1);
  --ds-color-tailwind-colors-fuchsia-50: rgba(253, 244, 255, 1);
  --ds-color-tailwind-colors-fuchsia-100: rgba(250, 232, 255, 1);
  --ds-color-tailwind-colors-fuchsia-200: rgba(246, 207, 255, 1);
  --ds-color-tailwind-colors-fuchsia-300: rgba(244, 168, 255, 1);
  --ds-color-tailwind-colors-fuchsia-400: rgba(237, 107, 255, 1);
  --ds-color-tailwind-colors-fuchsia-500: rgba(225, 42, 251, 1);
  --ds-color-tailwind-colors-fuchsia-600: rgba(200, 0, 222, 1);
  --ds-color-tailwind-colors-fuchsia-700: rgba(168, 0, 183, 1);
  --ds-color-tailwind-colors-fuchsia-800: rgba(138, 1, 148, 1);
  --ds-color-tailwind-colors-fuchsia-900: rgba(114, 19, 120, 1);
  --ds-color-tailwind-colors-fuchsia-950: rgba(75, 0, 79, 1);
  --ds-color-tailwind-colors-pink-50: rgba(253, 242, 248, 1);
  --ds-color-tailwind-colors-pink-100: rgba(252, 231, 243, 1);
  --ds-color-tailwind-colors-pink-200: rgba(252, 206, 232, 1);
  --ds-color-tailwind-colors-pink-300: rgba(253, 165, 213, 1);
  --ds-color-tailwind-colors-pink-400: rgba(251, 100, 182, 1);
  --ds-color-tailwind-colors-pink-500: rgba(246, 51, 154, 1);
  --ds-color-tailwind-colors-pink-600: rgba(230, 0, 118, 1);
  --ds-color-tailwind-colors-pink-700: rgba(198, 0, 92, 1);
  --ds-color-tailwind-colors-pink-800: rgba(163, 0, 76, 1);
  --ds-color-tailwind-colors-pink-900: rgba(134, 16, 67, 1);
  --ds-color-tailwind-colors-pink-950: rgba(81, 4, 36, 1);
  --ds-color-tailwind-colors-rose-50: rgba(255, 241, 242, 1);
  --ds-color-tailwind-colors-rose-100: rgba(255, 228, 230, 1);
  --ds-color-tailwind-colors-rose-200: rgba(255, 204, 211, 1);
  --ds-color-tailwind-colors-rose-300: rgba(255, 161, 173, 1);
  --ds-color-tailwind-colors-rose-400: rgba(255, 99, 126, 1);
  --ds-color-tailwind-colors-rose-500: rgba(255, 32, 86, 1);
  --ds-color-tailwind-colors-rose-600: rgba(236, 0, 63, 1);
  --ds-color-tailwind-colors-rose-700: rgba(199, 0, 54, 1);
  --ds-color-tailwind-colors-rose-800: rgba(165, 0, 54, 1);
  --ds-color-tailwind-colors-rose-900: rgba(139, 8, 54, 1);
  --ds-color-tailwind-colors-rose-950: rgba(77, 2, 24, 1);
  --ds-color-custom-colors-tilla-50: rgba(232, 239, 255, 1);
  --ds-color-custom-colors-tilla-100: rgba(213, 225, 255, 1);
  --ds-color-custom-colors-tilla-200: rgba(179, 198, 255, 1);
  --ds-color-custom-colors-tilla-300: rgba(133, 160, 255, 1);
  --ds-color-custom-colors-tilla-400: rgba(86, 106, 255, 1);
  --ds-color-custom-colors-tilla-500: rgba(47, 54, 255, 1);
  --ds-color-custom-colors-tilla-600: rgba(27, 12, 255, 1);
  --ds-color-custom-colors-tilla-700: rgba(30, 13, 255, 1);
  --ds-color-custom-colors-tilla-800: rgba(17, 6, 205, 1);
  --ds-color-custom-colors-tilla-900: rgba(20, 16, 159, 1);
  --ds-color-custom-colors-tilla-950: rgba(15, 10, 92, 1);
  --ds-color-colors-chart-1-light: rgba(42, 157, 144, 1);
  --ds-color-colors-chart-1-dark: rgba(38, 98, 217, 1);
  --ds-color-colors-chart-2-light: rgba(231, 110, 80, 1);
  --ds-color-colors-chart-2-dark: rgba(226, 54, 112, 1);
  --ds-color-colors-chart-3-light: rgba(39, 71, 84, 1);
  --ds-color-colors-chart-3-dark: rgba(232, 140, 48, 1);
  --ds-color-colors-chart-4-light: rgba(232, 196, 104, 1);
  --ds-color-colors-chart-4-dark: rgba(175, 87, 219, 1);
  --ds-color-colors-chart-5-light: rgba(244, 164, 98, 1);
  --ds-color-colors-chart-5-dark: rgba(46, 184, 138, 1);
  --ds-color-alpha-10: rgba(255, 255, 255, 0.9);
  --ds-color-alpha-20: rgba(255, 255, 255, 0.8);
  --ds-color-alpha-30: rgba(255, 255, 255, 0.7);
  --ds-color-alpha-40: rgba(255, 255, 255, 0.6);
  --ds-color-alpha-50: rgba(255, 255, 255, 0.5);
  --ds-color-alpha-60: rgba(255, 255, 255, 0.4);
  --ds-color-alpha-70: rgba(255, 255, 255, 0.3);
  --ds-color-alpha-80: rgba(255, 255, 255, 0.2);
  --ds-color-alpha-90: rgba(255, 255, 255, 0.1);
  --ds-color-colors-primary-light: var(--ds-color-tailwind-colors-indigo-600);
  --ds-color-colors-primary-dark: var(--ds-color-tailwind-colors-indigo-700);
  --ds-color-colors-primary-foreground-light: var(--ds-color-tailwind-colors-indigo-50);
  --ds-color-colors-primary-foreground-dark: var(--ds-color-tailwind-colors-indigo-50);
  --ds-color-colors-accent-light: var(--ds-color-tailwind-colors-indigo-50);
  --ds-color-colors-accent-dark: var(--ds-color-tailwind-colors-indigo-950);
  --ds-color-colors-accent-dim-light: var(--ds-color-tailwind-colors-indigo-200);
  --ds-color-colors-accent-dim-dark: var(--ds-color-tailwind-colors-indigo-900);
  --ds-color-colors-accent-foreground-dark: var(--ds-color-tailwind-colors-indigo-300);
  --ds-color-colors-accent-foreground-light: var(--ds-color-tailwind-colors-indigo-600);
  --ds-color-colors-primary-input-light: var(--ds-color-tailwind-colors-indigo-200);
  --ds-color-colors-primary-input-dark: var(--ds-color-tailwind-colors-indigo-500);
  --ds-color-colors-success-light: var(--ds-color-tailwind-colors-green-600);
  --ds-color-colors-success-dark: var(--ds-color-tailwind-colors-green-700);
  --ds-color-colors-success-foreground-light: var(--ds-color-tailwind-colors-green-50);
  --ds-color-colors-success-foreground-dark: var(--ds-color-tailwind-colors-green-50);
  --ds-color-colors-success-accent-light: var(--ds-color-tailwind-colors-green-50);
  --ds-color-colors-success-accent-dark: var(--ds-color-tailwind-colors-green-950);
  --ds-color-colors-success-accent-dim-light: var(--ds-color-tailwind-colors-green-200);
  --ds-color-colors-success-accent-dim-dark: var(--ds-color-tailwind-colors-green-900);
  --ds-color-colors-success-accent-foreground-light: var(--ds-color-tailwind-colors-green-600);
  --ds-color-colors-success-accent-foreground-dark: var(--ds-color-tailwind-colors-green-400);
  --ds-color-colors-success-input-light: var(--ds-color-tailwind-colors-green-200);
  --ds-color-colors-success-input-dark: var(--ds-color-tailwind-colors-green-800);
  --ds-color-colors-warning-light: var(--ds-color-tailwind-colors-amber-600);
  --ds-color-colors-warning-dark: var(--ds-color-tailwind-colors-amber-600);
  --ds-color-colors-warning-foreground-light: var(--ds-color-tailwind-colors-amber-50);
  --ds-color-colors-warning-foreground-dark: var(--ds-color-tailwind-colors-amber-50);
  --ds-color-colors-warning-accent-light: var(--ds-color-tailwind-colors-amber-50);
  --ds-color-colors-warning-accent-dark: var(--ds-color-tailwind-colors-amber-950);
  --ds-color-colors-warning-accent-dim-light: var(--ds-color-tailwind-colors-amber-200);
  --ds-color-colors-warning-accent-dim-dark: var(--ds-color-tailwind-colors-amber-900);
  --ds-color-colors-warning-accent-foreground-light: var(--ds-color-tailwind-colors-amber-600);
  --ds-color-colors-warning-accent-foreground-dark: var(--ds-color-tailwind-colors-amber-400);
  --ds-color-colors-warning-input-light: var(--ds-color-tailwind-colors-amber-200);
  --ds-color-colors-warning-input-dark: var(--ds-color-tailwind-colors-amber-800);
  --ds-color-colors-destructive-light: var(--ds-color-tailwind-colors-red-600);
  --ds-color-colors-destructive-dark: var(--ds-color-tailwind-colors-red-700);
  --ds-color-colors-destructive-foreground-light: var(--ds-color-tailwind-colors-red-50);
  --ds-color-colors-destructive-foreground-dark: var(--ds-color-tailwind-colors-red-50);
  --ds-color-colors-destructive-accent-light: var(--ds-color-tailwind-colors-red-50);
  --ds-color-colors-destructive-accent-dark: var(--ds-color-tailwind-colors-red-950);
  --ds-color-colors-destructive-accent-dim-light: var(--ds-color-tailwind-colors-red-200);
  --ds-color-colors-destructive-accent-dim-dark: var(--ds-color-tailwind-colors-red-900);
  --ds-color-colors-destructive-accent-foreground-light: var(--ds-color-tailwind-colors-red-600);
  --ds-color-colors-destructive-accent-foreground-dark: var(--ds-color-tailwind-colors-red-400);
  --ds-color-colors-destructive-input-light: var(--ds-color-tailwind-colors-red-200);
  --ds-color-colors-destructive-input-dark: var(--ds-color-tailwind-colors-red-800);
  --ds-color-colors-info-light: var(--ds-color-tailwind-colors-blue-600);
  --ds-color-colors-info-dark: var(--ds-color-tailwind-colors-blue-700);
  --ds-color-colors-info-foreground-light: var(--ds-color-tailwind-colors-blue-50);
  --ds-color-colors-info-foreground-dark: var(--ds-color-tailwind-colors-blue-50);
  --ds-color-colors-info-accent-light: var(--ds-color-tailwind-colors-blue-50);
  --ds-color-colors-info-accent-dark: var(--ds-color-tailwind-colors-blue-950);
  --ds-color-colors-info-accent-dim-light: var(--ds-color-tailwind-colors-blue-200);
  --ds-color-colors-info-accent-dim-dark: var(--ds-color-tailwind-colors-blue-900);
  --ds-color-colors-info-accent-foreground-light: var(--ds-color-tailwind-colors-blue-600);
  --ds-color-colors-info-accent-foreground-dark: var(--ds-color-tailwind-colors-blue-400);
  --ds-color-colors-info-input-light: var(--ds-color-tailwind-colors-blue-200);
  --ds-color-colors-info-input-dark: var(--ds-color-tailwind-colors-blue-800);
  --ds-color-colors-secondary-light: var(--ds-color-tailwind-colors-zinc-100);
  --ds-color-colors-secondary-dark: var(--ds-color-tailwind-colors-zinc-800);
  --ds-color-colors-secondary-accent-light: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-colors-secondary-accent-dark: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-colors-secondary-accent-dim-light: var(--ds-color-tailwind-colors-zinc-200);
  --ds-color-colors-secondary-accent-dim-dark: var(--ds-color-tailwind-colors-zinc-800);
  --ds-color-colors-secondary-foreground-light: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-colors-secondary-foreground-dark: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-colors-background-light: var(--ds-color-tailwind-colors-base-white);
  --ds-color-colors-background-dark: var(--ds-color-tailwind-colors-zinc-950);
  --ds-color-colors-foreground-light: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-colors-foreground-dark: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-colors-input-light: var(--ds-color-tailwind-colors-zinc-200);
  --ds-color-colors-input-dark: var(--ds-color-tailwind-colors-zinc-800);
  --ds-color-colors-border-light: var(--ds-color-tailwind-colors-zinc-200);
  --ds-color-colors-border-dark: var(--ds-color-tailwind-colors-zinc-800);
  --ds-color-colors-ring-light: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-colors-ring-dark: var(--ds-color-tailwind-colors-zinc-300);
  --ds-color-colors-muted-light: var(--ds-color-tailwind-colors-zinc-100);
  --ds-color-colors-muted-dark: var(--ds-color-tailwind-colors-zinc-800);
  --ds-color-colors-muted-foreground-light: var(--ds-color-tailwind-colors-zinc-500);
  --ds-color-colors-muted-foreground-dark: var(--ds-color-tailwind-colors-zinc-400);
  --ds-color-colors-muted-foreground-soft-light: var(--ds-color-tailwind-colors-zinc-300);
  --ds-color-colors-muted-foreground-soft-dark: var(--ds-color-tailwind-colors-zinc-600);
  --ds-color-colors-popover-light: var(--ds-color-tailwind-colors-base-white);
  --ds-color-colors-popover-dark: var(--ds-color-tailwind-colors-zinc-950);
  --ds-color-colors-popover-foreground-light: var(--ds-color-tailwind-colors-zinc-950);
  --ds-color-colors-popover-foreground-dark: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-colors-card-light: var(--ds-color-tailwind-colors-base-white);
  --ds-color-colors-card-dark: var(--ds-color-tailwind-colors-zinc-950);
  --ds-color-colors-card-foreground-light: var(--ds-color-tailwind-colors-zinc-950);
  --ds-color-colors-card-foreground-dark: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-colors-tooltip-background-light: var(--ds-color-tailwind-colors-zinc-800);
  --ds-color-colors-tooltip-background-dark: var(--ds-color-tailwind-colors-zinc-100);
  --ds-color-colors-tooltip-foreground-light: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-colors-tooltip-foreground-dark: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-colors-sidebar-foreground-light: var(--ds-color-tailwind-colors-zinc-700);
  --ds-color-colors-sidebar-background-light: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-colors-sidebar-background-dark: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-colors-sidebar-foreground-dark: var(--ds-color-tailwind-colors-zinc-100);
  --ds-color-colors-sidebar-primary-light: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-colors-sidebar-primary-dark: var(--ds-color-tailwind-colors-blue-700);
  --ds-color-colors-sidebar-primary-foreground-light: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-colors-sidebar-primary-foreground-dark: var(--ds-color-tailwind-colors-base-white);
  --ds-color-colors-sidebar-accent-light: var(--ds-color-tailwind-colors-zinc-100);
  --ds-color-colors-sidebar-accent-dark: var(--ds-color-tailwind-colors-zinc-800);
  --ds-color-colors-sidebar-accent-foreground-light: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-colors-sidebar-accent-foreground-dark: var(--ds-color-tailwind-colors-zinc-100);
  --ds-color-colors-sidebar-border-light: var(--ds-color-tailwind-colors-gray-200);
  --ds-color-colors-sidebar-border-dark: var(--ds-color-tailwind-colors-zinc-800);
  --ds-color-colors-sidebar-ring-light: var(--ds-color-tailwind-colors-zinc-400);
  --ds-color-colors-sidebar-ring-dark: var(--ds-color-tailwind-colors-zinc-300);
  --ds-color-base-primary: var(--ds-color-tailwind-colors-indigo-600);
  --ds-color-base-primary-foreground: var(--ds-color-tailwind-colors-indigo-50);
  --ds-color-base-accent: var(--ds-color-tailwind-colors-indigo-50);
  --ds-color-base-accent-dim: var(--ds-color-tailwind-colors-indigo-200);
  --ds-color-base-accent-foreground: var(--ds-color-tailwind-colors-indigo-600);
  --ds-color-base-primary-input: var(--ds-color-tailwind-colors-indigo-200);
  --ds-color-base-success: var(--ds-color-tailwind-colors-green-600);
  --ds-color-base-success-foreground: var(--ds-color-tailwind-colors-green-50);
  --ds-color-base-success-accent: var(--ds-color-tailwind-colors-green-50);
  --ds-color-base-success-accent-dim: var(--ds-color-tailwind-colors-green-200);
  --ds-color-base-success-accent-foregound: var(--ds-color-tailwind-colors-green-600);
  --ds-color-base-success-input: var(--ds-color-tailwind-colors-green-200);
  --ds-color-base-warning: var(--ds-color-tailwind-colors-amber-600);
  --ds-color-base-warning-foreground: var(--ds-color-tailwind-colors-amber-50);
  --ds-color-base-warning-accent: var(--ds-color-tailwind-colors-amber-50);
  --ds-color-base-warning-accent-dim: var(--ds-color-tailwind-colors-amber-200);
  --ds-color-base-warning-accent-foregound: var(--ds-color-tailwind-colors-amber-600);
  --ds-color-base-warning-input: var(--ds-color-tailwind-colors-amber-200);
  --ds-color-base-destructive: var(--ds-color-tailwind-colors-red-600);
  --ds-color-base-destructive-foreground: var(--ds-color-tailwind-colors-red-50);
  --ds-color-base-destructive-accent: var(--ds-color-tailwind-colors-red-50);
  --ds-color-base-destructive-accent-dim: var(--ds-color-tailwind-colors-red-200);
  --ds-color-base-destructive-accent-foregound: var(--ds-color-tailwind-colors-red-600);
  --ds-color-base-destructive-input: var(--ds-color-tailwind-colors-red-200);
  --ds-color-base-info: var(--ds-color-tailwind-colors-blue-600);
  --ds-color-base-info-foreground: var(--ds-color-tailwind-colors-blue-50);
  --ds-color-base-info-accent: var(--ds-color-tailwind-colors-blue-50);
  --ds-color-base-info-accent-dim: var(--ds-color-tailwind-colors-blue-200);
  --ds-color-base-info-accent-foregound: var(--ds-color-tailwind-colors-blue-600);
  --ds-color-base-info-input: var(--ds-color-tailwind-colors-blue-200);
  --ds-color-base-secondary: var(--ds-color-tailwind-colors-zinc-100);
  --ds-color-base-secondary-accent: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-base-secondary-accent-dim: var(--ds-color-tailwind-colors-zinc-200);
  --ds-color-base-secondary-foreground: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-base-background: var(--ds-color-tailwind-colors-base-white);
  --ds-color-base-foreground: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-base-input: var(--ds-color-tailwind-colors-zinc-200);
  --ds-color-base-border: var(--ds-color-tailwind-colors-zinc-200);
  --ds-color-base-ring: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-base-muted: var(--ds-color-tailwind-colors-zinc-100);
  --ds-color-base-muted-foreground: var(--ds-color-tailwind-colors-zinc-500);
  --ds-color-base-muted-foreground-soft: var(--ds-color-tailwind-colors-zinc-300);
  --ds-color-base-ring-offset: var(--ds-color-tailwind-colors-base-white);
  --ds-color-base-popover: var(--ds-color-tailwind-colors-base-white);
  --ds-color-base-popover-foreground: var(--ds-color-tailwind-colors-zinc-950);
  --ds-color-base-card: var(--ds-color-tailwind-colors-base-white);
  --ds-color-base-card-foreground: var(--ds-color-tailwind-colors-zinc-950);
  --ds-color-base-tooltip-background: var(--ds-color-tailwind-colors-zinc-800);
  --ds-color-base-tooltip-foreground: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-base-chart-1: var(--ds-color-colors-chart-1-light);
  --ds-color-base-chart-2: var(--ds-color-colors-chart-2-light);
  --ds-color-base-chart-3: var(--ds-color-colors-chart-3-light);
  --ds-color-base-chart-4: var(--ds-color-colors-chart-4-light);
  --ds-color-base-chart-5: var(--ds-color-colors-chart-5-light);
  --ds-color-base-sidebar-primary-foreground: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-base-sidebar-primary: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-base-sidebar-foreground: var(--ds-color-tailwind-colors-zinc-700);
  --ds-color-base-sidebar-background: var(--ds-color-tailwind-colors-zinc-50);
  --ds-color-base-sidebar-accent: var(--ds-color-tailwind-colors-zinc-100);
  --ds-color-base-sidebar-accent-foreground: var(--ds-color-tailwind-colors-zinc-900);
  --ds-color-base-sidebar-border: var(--ds-color-tailwind-colors-gray-200);
  --ds-color-base-sidebar-ring: var(--ds-color-tailwind-colors-zinc-400);
}
